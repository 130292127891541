<template>
    <div class="cm_page">
        <div class="cm_main">
            <div class="cm_nav">
                <el-breadcrumb class="crumb" separator="/">
                    <el-breadcrumb-item :to="{ path: '/bus-customManage' }">客户管理</el-breadcrumb-item>
                    <el-breadcrumb-item :to="{ path: '/peizhiManage' }">配制管理</el-breadcrumb-item>
                </el-breadcrumb>
            </div>
            {{optionsData}}
            <el-collapse class="collapse" v-model="activeName" accordion v-loading="dataListLoading">
                <el-collapse-item v-for="(item,index) in list" :key="index" :name="index" >
                    <template slot="title">
                      <!-- {{'配制'+item.settingCode+(item.jsonData.title?'-'+item.jsonData.title:'')}}
                      <a target="_blank" :href="$filePath+'data/'+item.custom.createuser+'/'+item.custom.id+'-'+item.settingCode+'.json'">
                        {{item.custom.id+'-'+item.settingCode+'.json'}}
                      </a> -->
                      张三 客厅的配制 <a target="_blank">18300000000/10001</a>
                    </template>
                    <div>
                        <el-form class="pzform" ref="form" :model="item.jsonData" label-width="110px">
                            <div class="head">
                                <el-form-item label="主题名">
                                    <el-input v-model="item.jsonData.title"></el-input>
                                </el-form-item>
                                <el-form-item label="支持系统">
                                    <el-select v-model="item.jsonData.supportSystem">
                                        <el-option v-for="(option,index) in optionsData.supportSystem || []" 
                                        :key="index" :label="option.name" :value="option"></el-option>
                                    </el-select>
                                </el-form-item>
                                <el-form-item label="分类页面">
                                    <el-select v-model="item.jsonData.enableReceivedCommand">
                                        <el-option v-for="(option,index) in optionsData.classPage || []" 
                                        :key="index" :label="option.name" :value="option"></el-option>
                                    </el-select>
                                </el-form-item>
                            </div>
                            <!-- {{item.jsonData.list}} -->
                            <draggable v-model="item.jsonData.list">
                                <div class="item" v-for="(command,cIndex) in item.jsonData.list" :key="cIndex">
                                
                                <el-form-item label="类别">
                                    <div slot="label">类别 <img style="width:26px" :src="$imgPath+'1.png'" /></div>
                                    <el-select v-model="command.aa" filterable allow-create @visible-change="setFocus">
                                        <el-option v-for="(option,index) in optionsData.deviceType || []" 
                                        :key="index" :label="option.name" :value="option">
                                            <span style="float: left"><img style="width:26px" :src="$imgPath+option.data.icon" /></span>
                                            <span style="float: right;">{{option.name}}</span>
                                        </el-option>
                                    </el-select>
                                </el-form-item>
                                <el-form-item label="按钮名称">
                                    <div slot="label">按钮名称 <img style="width:26px" :src="$imgPath+'1.png'" /></div>
                                    <el-select v-model="command.commandName" filterable allow-create @visible-change="setFocus">
                                        <el-option v-for="(option,index) in getOptions('commandName')" 
                                        :key="index" :label="option.label" :value="option.value">
                                        </el-option>
                                    </el-select>
                                </el-form-item>
                                <el-form-item label="按钮类型">
                                    <el-select v-model="command.deviceType" filterable allow-create @visible-change="setFocus">
                                        <el-option v-for="(option,index) in getOptions('deviceType')" 
                                        :key="index" :label="option.label" :value="option.value">
                                            <span style="float: left"><img style="width:26px" :src="$imgPath+option.icon" /></span>
                                            <span style="float: right;">{{option.label}}</span>
                                        </el-option>
                                    </el-select>
                                </el-form-item>
                                <el-form-item label="按钮数据">
                                    <el-select v-model="command.commandBytes" filterable allow-create @visible-change="setFocus">
                                        <el-option v-for="(option,index) in getOptions('commandBytes')" 
                                        :key="index" :label="option.label" :value="option.value">
                                        </el-option>
                                    </el-select>
                                </el-form-item>
                                <el-form-item label="二级菜单">
                                    <el-select v-model="command.url" filterable allow-create @visible-change="setFocus">
                                        <el-option v-for="(option,index) in getOptions('url')" 
                                        :key="index" :label="option.label" :value="option.value">
                                        </el-option>
                                    </el-select>
                                </el-form-item>
                                <el-form-item label="按钮数据2">
                                    <el-select v-model="command.url" filterable allow-create @visible-change="setFocus">
                                        <el-option v-for="(option,index) in getOptions('url')" 
                                        :key="index" :label="option.label" :value="option.value">
                                        </el-option>
                                    </el-select>
                                </el-form-item>
                                </div>
                            </draggable>
                           
                            <div>
                                <el-button type="primary" @click="item.jsonData.list.push({})">添加条目</el-button>
                                <el-button type="success" @click="save(item)">保存配制</el-button>
                                <el-button type="danger" @click="del(item)">删除配制</el-button>
                            </div>
                        </el-form>
                    </div>
                </el-collapse-item>
            </el-collapse>
            <el-button type="primary" @click="addSettingData()">添加配制</el-button>
        </div>
        
    </div>
</template>
<script>
import draggable from 'vuedraggable'
export default {
  components: {
    draggable
  },
  data() {
    return {
      list: [],
      dataListLoading: false,
      activeName: null,
      baseData: [],
      // 下拉数据
      optionsData: {}
    }
  },
  methods: {
    setFocus(event) {
      if (event) {
        setTimeout(() => {
          var el = document.activeElement
          el.blur()
          el.focus()
        }, 0)
      }

           // this.$refs.ss.focus()
    },
    // getData() {
    //   this.dataListLoading = true
    //   this.$http({
    //     url: this.$http.adornUrl('/bus/settingData/getAllJson/' + this.$route.query.customId),
    //     method: 'post'
    //   }).then(({data}) => {
    //     var jsonDatas = data.data
    //     if (jsonDatas) {
    //       for (var i in jsonDatas) {
    //         var jsonObj = jsonDatas[i].jsonData
    //         for (var deviceIndex in jsonObj.deviceList) {
    //           var device = jsonObj.deviceList[deviceIndex]
    //           var list = []

    //           for (var commandIndex in device.commands) {
    //             var command = device.commands[commandIndex]
    //             command.classDeviceType = device.deviceType
    //             list.push(command)
    //           }
    //           list = _.sortBy(list, function(item) { return item.sort })
    //           jsonObj.list = list
    //         }
    //       }
    //     }
    //     this.dataListLoading = false
    //     this.list = jsonDatas
    //   })
    // },
    getConfigData() {
      this.$http({
        url: this.$http.adornUrl('/bus/settingData/getSettingData'),
        method: 'post'
      }).then(({data}) => {
        this.baseData = data.configJson.baseData
      })
    },
    getOptions(field) {
      if (this.baseData) {
        var data = this.baseData.find(item => item.field === field)
        if (data && data.options) {
          return data.options
        }
      }
      return []
    },
    save(item) {
      console.log(item)
      var data = {
        custom: item.custom,
        jsonData: {},
        settingCode: item.settingCode
      }
      data.jsonData.title = item.jsonData.title
      data.jsonData.enableReceivedCommand = item.jsonData.enableReceivedCommand
      data.jsonData.wakeupToHomeCommand = item.jsonData.wakeupToHomeCommand
      data.jsonData.deviceList = []
      for (var i in item.jsonData.list) {
        var command = item.jsonData.list[i]
        var device = data.jsonData.deviceList.find(d => d.deviceType === command.classDeviceType)
        if (!device) {
          var option = this.getOptions('classDeviceType').find(o => o.value === command.classDeviceType)
          device = {deviceType: command.classDeviceType,
            deviceName: option && option.label}
          data.jsonData.deviceList.push(device)
        }
        if (!device.commands)device.commands = []
        command.sort = Number(i) + 1
        device.commands.push(command)
      }
      console.log(data)
      this.$http({
        url: this.$http.adornUrl('/bus/settingData/save'),
        method: 'post',
        data: this.$http.adornData(data)
      }).then(({data}) => {
        if (data && data.code === 0) {
          this.$message({
            message: '保存成功',
            type: 'success'
          })
        }
      })
    },
    addSettingData() {
      this.$http({
        url: this.$http.adornUrl('/bus/settingData/add/' + this.$route.query.customId),
        method: 'post'
      }).then(({data}) => {
        data.settingData.jsonData = {
          'enableReceivedCommand': '',
          'list': [{
            'deviceType': 1,
            'commandName': '',
            'commandBytes': '',
            'classDeviceType': 1,
            'sort': 1,
            'url': ''
          }],
          'title': '',
          'wakeupToHomeCommand': ''
        }
        this.list.push(data.settingData)
      })
    },
    del(item) {
      this.$confirm('是否确定删除配制？', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(() => {
        this.$http({
          url: this.$http.adornUrl('/bus/settingData/del'),
          method: 'post',
          data: this.$http.adornData(item)
        }).then(({data}) => {
          if (data && data.code === 0) {
            this.list.splice(this.list.indexOf(item), 1)
            this.$message({
              message: '删除成功',
              type: 'success'
            })
          }
        })
      })
    },
    // 获取下拉基础数据
    getData(code) {
      this.$http({
        url: this.$http.adornUrl('/bus/busdata/list/' + code),
        method: 'post',
        data: this.$http.adornData({})
      }).then(({data}) => {
        if (data && data.code === 0) {
          for (var row of data.list) {
            row.data = JSON.parse(row.data)
          }
          this.$set(this.optionsData, code, data.list)
        }
      })
    }

  },
  mounted() {
    this.getConfigData()
    this.getData('supportSystem')
    this.getData('classPage')
    this.getData('deviceType')
    this.getData('keyType')
    this.getData('commandBytes')
    this.getData('menus')
    this.getData('url')
  }
}
</script>
<style scoped lang="scss">
    .collapse{
        padding-left: 15px;
        background-color: #fff;
        margin-bottom: 15px;
        .pzform{
            padding-right: 15px;
            .head{
                display: flex;
                flex-wrap: wrap;
                .el-form-item{
                    width: 230px;
                }
            }
            .el-select{
                width: 100%;
            }
            .item{
                display: flex;
                flex-wrap: wrap;
                background-color: #f5f7fa;
                padding: 20px 20px 0;
                margin-bottom: 15px;
                .el-form-item{
                    width: 220px;
                }
            }
            
        }
    }
</style>